<template>
  <div class="list-table">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
          <v-card>
            <v-toolbar card color="white">
              <v-text-field
                flat
                solo
                prepend-icon="search"
                placeholder="Filtrar"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon @click="cadastro">
                <v-icon>add_circle_outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="items"
                :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
                class="elevation-1"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.item.id }}
                  </td>
                  <td>{{ props.item.referenciaInformada }}</td>
                  <td>{{ props.item.dataGeracao }}</td>
                  <td>{{ props.item.quantidade }}</td>
                  <td>
                    <v-btn depressed icon dark color="primary" small @click="imprimir(props.item.id)">
                      <v-icon>print</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar :color="'pink accent-1'" v-model="snackbar" :top="true">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { ServicoCodigoBarras } from "../../servicos/servicoCodigoBarras"
import formataDateTime from "../../util/formatDateTime"
const servicoCodigoBarras = new ServicoCodigoBarras()
export default {
  data() {
    return {
      snackbar: false,
      text: "Gerando arquivo para impressão. Aguarde!",
      search: "",
      items: [],
      headers: [
        {
          text: "Código",
          align: "left",
          value: "id",
          sortable: false
        },
        { text: "Referência", align: "left", sortable: false, value: "referenciaInformada" },
        { text: "Data", align: "left", sortable: false, value: "dataGeracao" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "", value: "action", sortable: false, align: "right" }
      ]
    }
  },
  created() {
    servicoCodigoBarras.buscarCodigoBarras().then(
      res => {
        if (res.status === 200) {
          this.items = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    imprimir(id) {
      this.snackbar = true
      var data = formataDateTime.formatarDataHora(new Date())
      servicoCodigoBarras.imprimir(id).then(response => {
        var blob = new Blob([response.data], { type: "application/pdf" })
        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        var fileName = `${data}.pdf`
        link.download = fileName
        link.click()
        this.snackbar = false
      })
    },
    cadastro() {
      this.$router.push({ name: "cadastro-codigoBarras" })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
