export default {
  formatarDataHora(data) {
    return (
      data.getDate() +
      "-" +
      data.getMonth() +
      "-" +
      data.getFullYear() +
      "_" +
      data.getHours() +
      ":" +
      data.getMinutes()
    )
  }
}
