<template>
  <div id="pageCodigoBarras">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg8 sm12 xs12>
          <codigo-barras-table></codigo-barras-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import CodigoBarrasTable from "@/components/codigoBarras/tableCodigoBarras"
export default {
  components: {
    CodigoBarrasTable
  }
}
</script>
